import React, { memo, useState, useEffect } from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import settingsParams from 'FeedWidget/settingsParams';
import { EmptyState } from 'Group/Widget/EmptyState/EmptyState';

import { GroupDetails } from './GroupDetails';
import { Feed } from './Feed';

import { st, classes } from './Widget.st.css';
import { JoinGroup } from 'FeedWidget/Widget/JoinGroup/JoinGroup';

export const Widget = memo(
  () => {
    const { t } = useTranslation();
    const settings = useSettings();
    const groupId = settings.get(settingsParams.groupId);

    const [opened, setOpened] = useState(false);

    if (!groupId) {
      return (
        <EmptyState
          title={t('groups-web.settings.empty.title')}
          content={t('groups-web.settings.empty.description')}
        />
      );
    }

    return (
      <div className={st(classes.root, {})} data-hook="TestTmp-wrapper">
        <GroupDetails />
        <Feed onGuard={handleOpen} groupId={groupId} />
        <JoinGroup isOpen={opened} onClose={handleClose} />
      </div>
    );

    function handleOpen() {
      setOpened(true);
    }

    function handleClose() {
      setOpened(false);
    }
  },
  () => true,
);
